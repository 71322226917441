import { SimpleHorse } from "@/openapi";
import {
  getVetrarFromFaedingarnumer,
  sexEnumToString,
} from "@/utils/horseUtils";
import Link from "next/link";
import HorseAvatar from "../misc/HorseAvatar";

interface ISimpleHorseCardProps {
  simpleHorse: SimpleHorse;
  // only name
  onlyNameImage?: boolean;
  onClick?: (horse: SimpleHorse) => void;
  notNavigate?: boolean;
}

const SimpleHorseCard = (props: ISimpleHorseCardProps) => {
  const { simpleHorse } = props;

  const vetrar = getVetrarFromFaedingarnumer(simpleHorse.faedingarnumer);

  const renderCard = () => {
    return (
      <div
        // style={{ maxWidth: "16rem" }}
        className="mt-2  p-2 rounded-md cursor-pointer"
        onClick={
          !props.notNavigate
            ? undefined
            : () => {
                if (props.onClick) {
                  props.onClick!(simpleHorse);
                }
              }
        }
      >
        <div className="flex flew-row items-center">
          <HorseAvatar
            simpleHorse={simpleHorse}
            className=""
            radius={props.onlyNameImage ? 20 : 40}
          />
          <div className="ml-4">
            <div
              className={
                "text-dark-gray " + props.onlyNameImage ? "text-xs" : "text-sm"
              }
            >
              {simpleHorse.name} {props.onlyNameImage && "| " + vetrar}
            </div>
            {!props.onlyNameImage && (
              <div className="text-dark-gray text-xs">
                {simpleHorse.faedingarnumer} | {vetrar} |{"   "}
                {sexEnumToString(simpleHorse.sexEnum)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (props.onClick) {
    return renderCard();
  }

  return (
    <Link href={"/horses/" + simpleHorse.faedingarnumer}>{renderCard()}</Link>
  );
};

export default SimpleHorseCard;
