import { HorsedayIconProps } from "./IconProps";

const TrainingIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  return (
    <svg
      width={props.width ?? "24"}
      height={props.height ?? "24"}
      fill={props.fill ?? "none"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.89844 21.6494C7.89844 20.3747 8.93136 19.3418 10.206 19.3418C11.4807 19.3418 12.5136 20.3747 12.5136 21.6494"
        stroke={stroke}
        strokeWidth="1.09885"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2109 16.3027V19.3429"
        stroke={stroke}
        strokeWidth="1.09885"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0686 8.04786C8.68042 8.04786 6.53399 7.02959 5.04688 5.59375V15.293L11.5741 16.5896C13.5447 16.9779 15.3761 15.4761 15.3761 13.4616V7.32994"
        stroke={stroke}
        strokeWidth="1.09885"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.04688 3.16056H7.12005C8.29949 4.27407 9.89649 4.95536 11.6473 4.95536C14.2992 4.95536 16.6068 3.39499 17.6544 1.13867L18.9437 2.39137C18.6239 3.75549 17.8854 4.96841 16.8474 5.91616"
        stroke={stroke}
        strokeWidth="1.09885"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8896 6.49333C18.8896 6.49333 18.1089 8.59224 15.6788 9.42311"
        stroke={stroke}
        strokeWidth="1.09885"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
    </svg>
  );
};
export default TrainingIcon;
