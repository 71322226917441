import { Actor, PublicUser, SimpleUser } from "@/openapi";
import Person from "@mui/icons-material/Person";
import Image from "next/image";

interface UserAvatarProps {
  user: SimpleUser | PublicUser | Actor | undefined;
  className?: string;
  radius?: number;
}

const UserAvatar = (props: UserAvatarProps) => {
  const { user } = props;

  const width = props.radius ?? 50;
  const height = props.radius ?? 50;
  return (
    <div className={"relative " + props.className}>
      {user?.avatar ? (
        <Image
          style={{ width: width, height: height }}
          className="h-full w-full object-cover rounded-full"
          src={user.avatar?.toString()}
          alt={user.name || "User"}
          width={width}
          height={height}
        />
      ) : (
        <div
          style={{ width: width, height: height }}
          className="bg-lightest-gray rounded-full  justify-center flex flex-col items-center"
        >
          <Person />
        </div>
      )}
    </div>
  );
};

export default UserAvatar;
