import React, { ReactNode, useState } from "react";
import clsx from "clsx";
import { _ } from "@/pages/_app";
import { CalendarDay, getMonthDays, weekDays } from "@/utils/dateUtils";
import FullscreenIcon from "../icons/FullscreenIcon";

interface CalendarProps {
  month: number;
  year: number;
  dayBuilder: (day: CalendarDay, date: Date) => ReactNode | undefined;
  onMonthChange?: (next: boolean) => void;
  onFullscreen?: () => void;
}

const Calendar = ({ month, year, dayBuilder, ...props }: CalendarProps) => {
  const [selectedDate, setSelectedDate] = useState<number>();

  const monthDays = getMonthDays(month, year);

  const handleDayClick = (date: number) => {
    setSelectedDate(date);
  };

  return (
    <div className="bg-white rounded-lg p-4">
      <div className="flex justify-between items-center mb-2">
        <div className="font-bold text-lg">
          {new Date(year, month).toLocaleDateString("en-US", {
            month: "long",
            year: "numeric",
          })}
        </div>
        <div className="flex underline">
          <button
            className="mr-2"
            onClick={() => {
              if (props.onMonthChange) props.onMonthChange(false);
            }}
          >
            Prev
          </button>
          <button
            onClick={() => {
              if (props.onMonthChange) props.onMonthChange(true);
            }}
          >
            Next
          </button>
          {props.onFullscreen && (
            <button
              className="ml-2"
              onClick={() => {
                if (props.onFullscreen) props.onFullscreen();
              }}
            >
              <FullscreenIcon />
            </button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-7 gap-2 mb-2">
        {weekDays.map((day) => (
          <div
            key={day}
            className="text-center font-bold text-gray-600 uppercase text-sm"
          >
            {_(day)}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-2">
        {monthDays.map((day, i) => {
          const elm = dayBuilder(day, new Date(year, month, day.date, 8, 0));
          if (elm) {
            return <div key={day.date + "" + i}>{elm}</div>;
          }
          return (
            <button
              key={day.date + "" + i}
              className={clsx(
                "w-full p-2 rounded-full text-center",
                day.isCurrentMonth ? "text-black" : "text-gray-200"
              )}
              onClick={() => handleDayClick(day.date)}
            >
              {day.date}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Calendar;
