import Spinner from "../misc/Spinner";
import { clsx } from "clsx";

export interface ButtonProps {
  onClick: () => any;
  className?: string;
  icon?: JSX.Element | string;
  children: JSX.Element | string | string[];
  loading?: boolean;
}

const Button = (props: ButtonProps) => {
  return (
    <button
      onClick={() => {
        if (props.loading) {
          return;
        }
        props.onClick();
      }}
      style={{ maxHeight: 64}}
      className={clsx(
        " bg-primary-brown hover:shadow-sm w-full mb-4 shadow-md text-white font-medium py-2 px-4 rounded-lg text-sm pt-3 pb-3 ",
        props.className && props.className!.includes("bg-")
          ? ""
          : "hover:bg-primary-brown-hover ",
        props.className
      )}
      type="button"
    >
      {props.loading ? (
        <Spinner className="flex flex-row justify-center" size={5} />
      ) : (
        <>
          {props.icon != null && (
            <div className="inline mr-8 ">{props.icon}</div>
          )}
          {props.children}
        </>
      )}
    </button>
  );
};

export default Button;
