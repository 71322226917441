import { _ } from "@/pages/_app";
import { lightBrown } from "@/utils/colors";
import Link from "next/link";
import FeedIcon from "./icons/FeedIcon";
import HorseIcon from "./icons/HorseIcon";
import RidersIcon from "./icons/RidersIcon";
import TrainingIcon from "./icons/TrainingIcon";

interface NavItem {
  href: string;
  label: string;
  icon: React.ReactElement;
}

const navItems: NavItem[] = [
  { label: "Feed", href: "/", icon: <FeedIcon color={lightBrown} /> },
  {
    label: "Horses",
    href: "/horses",
    icon: <HorseIcon height={24} color={lightBrown} />,
  },
  { label: "Riders", href: "/riders", icon: <RidersIcon color={lightBrown} /> },
  {
    label: "Training",
    href: "/training",
    icon: <TrainingIcon color={lightBrown} />,
  },
];

interface INavItemsProps {
  showIcon?: boolean;
  color?: string;
}

export const NavItems = (props: INavItemsProps) => {
  var c = "block py-2 pl-3 pr-4";
  return (
    <>
      {navItems.map((item) => {
        let c1 = c;
        if (
          typeof window !== "undefined" &&
          item.href === window.location.pathname
        ) {
          c1 += " font-bold";
        }
        return (
          <li key={item.label}>
            <Link href={item.href} className={c1}>
              <div className="flex flex-row relative">
                {props.showIcon && (
                  <div className="mr-4 absolute" style={{ left: -30, top: 0 }}>
                    {item.icon}
                  </div>
                )}
                {_(item.label)}
              </div>
            </Link>
          </li>
        );
      })}
    </>
  );
};

const Footer = () => {
  return (
    <footer className=" sm:block md:hidden h-100 bg-white fixed bottom-0 w-full shadow-md border-2">
      <ul className="pl-12 pr-12 flex flex-wrap items-center justify-between  text-light-brown  md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 ">
        <NavItems />
      </ul>
    </footer>
  );
};

export default Footer;
