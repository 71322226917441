interface ICloseButton {
  onClick: () => void;
}

const CloseButton = (props: ICloseButton) => {
  return (
    <div
      className="justify-center items-center text-md rounded-md h-8 w-8 text-center bg-lightest-gray text-stone-gray hover:text-black hover:bg-surface leading-8 cursor-pointer"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        props.onClick();
      }}
    >
      X
    </div>
  );
};

export default CloseButton;
