import { PublicUser, User } from "@/openapi";
import { IStore } from "@/redux/store";
import { captureException } from "@/utils/devUtils";
import { accountsApi } from "@/utils/NetworkUtils";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import UserProfile from "./userProfile";

interface IMyUserProfile {
  user?: User;
}

const MyUserProfile = (props: IMyUserProfile) => {
  const [profile, setProfile] = useState(undefined as undefined | PublicUser);

  useEffect(() => {
    if (props.user) {
      accountsApi
        .accountsUserProfileRetrieve(props.user.uid)
        .then((e) => {
          setProfile(e);
        })
        .catch((e) => {
          captureException(e);
        });
    }
  }, [props.user]);

  if (!profile) {
    return <></>;
  }

  return (
    <UserProfile user={profile} oneColumn={true} simpleHorseCards={true} />
  );
};

const mapStateToProps = (state: IStore) => {
  const { user } = state.userState || undefined;

  return { user };
};

export default connect(mapStateToProps)(MyUserProfile);
