import { Horse, SearchHorse, SimpleHorse } from "@/openapi";
import Image from "next/image";
import HorseIcon from "../icons/HorseIcon";
import { clsx } from "clsx";

interface HorseAvatarProps {
  horse?: Horse;
  simpleHorse?: SimpleHorse | SearchHorse;
  radius?: number;
  className?: string;
  color?: string;
}

const HorseAvatar = (props: HorseAvatarProps) => {
  let photo;

  if (props.horse) {
    photo = props.horse.photos.find((e) => e.inProfile);
    if (!photo && props.horse.photos.length > 0) {
      photo = props.horse.photos[0];
    }
  } else if (props.simpleHorse) {
    photo = props.simpleHorse.avatar;
  }

  const width = props.radius ?? 50;
  const height = props.radius ?? 50;

  if (!photo) {
    return (
      <div
        style={{ width: width, height: height }}
        className={clsx(
          "rounded-full object-fill  flex justify-center items-center " +
            (props.className ?? ""),
          props.color ? "bg-" + props.color : "bg-light-brown"
        )}
      >
        <HorseIcon width={width - 6} height={height - 6} />
      </div>
    );
  }
  return (
    <Image
      className={"rounded-full object-cover " + (props.className ?? "")}
      style={{ height: height, width: "auto" }}
      alt="image of horse"
      src={photo?.thumbnail!}
      width={width}
      height={height}
    />
  );
};

export default HorseAvatar;
