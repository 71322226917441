import { _ } from "@/pages/_app";
import { useRouter } from "next/router";

interface IAddActivityButton {
  onClick: () => void;
}

const AddActivityButton = (props: IAddActivityButton) => {
  return (
    <div
      className="flex flex-row items-center pr-12 text-light-brown font-light cursor-pointer"
      onClick={props.onClick}
    >
      <div className="h-8 w-8 rounded-full bg-light-brown-100 items-center justify-center relative">
        <div
          className="h-7 w-7 rounded-full bg-light-brown-200 absolute "
          style={{
            top: 2,
            left: 2,
          }}
        >
          <div
            className="h-6 w-6 rounded-full bg-light-brown absolute "
            style={{
              top: 2,
              left: 2,
            }}
          >
            <div
              className="h-5 bg-white absolute"
              style={{
                width: 1,
                top: 4,
                left: 12,
                height: 16,
              }}
            ></div>
            <div
              className="w-4 bg-white absolute"
              style={{
                width: 16,
                height: 1,
                top: 11.5,
                left: 4,
              }}
            ></div>
          </div>
        </div>
      </div>
      <span className="pl-4">{_("Add activity")}</span>
    </div>
  );
};

export default AddActivityButton;
