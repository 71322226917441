import { HorsedayIconProps } from "./IconProps";

const HorseIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  return (
    <svg
      className={props.className}
      width={props.width ?? "32"}
      height={props.height ?? "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66406 13.52C5.82406 12.8867 8.78406 12.3267 12.1374 12.3267"
        stroke="#E97451"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3735 13.28L24.2335 13.92C23.7335 16.16 22.8402 18.2934 21.5935 20.2134L19.5869 23.3134L14.5469 25.5867"
        stroke="#E97451"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7019 22.76L27.1285 19.64L22.9219 18.78"
        stroke="#E97451"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5078 18.5933L16.2811 19.16C18.0011 19.5133 20.1411 19.5733 22.0878 18.8667"
        stroke="#E97451"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5128 17.2999C12.1395 22.0733 7.06615 20.4533 7.06615 20.4533L3.63281 22.7599"
        stroke="#E97451"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5558 7.33331L26.2558 9.01331L30.2492 12.4C29.7825 13.08 28.8958 14.3933 26.7692 12.9466C26.7692 12.9466 24.5758 13.58 24.0625 11.7933"
        stroke="#E97451"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5841 7.73328C22.5174 7.95994 19.6107 8.88661 18.7441 12.3266H12.3507C9.13073 12.3266 5.88406 13.2333 6.23739 17.7866"
        stroke="#E97451"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9032 25.74L13.9766 22.92L12.9766 20.0733"
        stroke="#E97451"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default HorseIcon;
