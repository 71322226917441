import Image from "next/image";
import { IStore } from "@/redux/store";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { connect } from "react-redux";
import { User } from "@/openapi";
import Link from "next/link";
import { NavItems } from "./footer";
import SearchBar from "./inputs/searchBar";
import { useRouter } from "next/router";
import { getCurrentPath } from "@/utils/devUtils";
import { _ } from "@/pages/_app";
import AddActivityButton from "./buttons/AddActivityButton";
import { Message } from "@mui/icons-material";
import { lightBrown } from "@/utils/colors";

interface INavbarProps {
  user: User | undefined;
}

const Navbar = (props: INavbarProps) => {
  const router = useRouter();
  const renderUserImage = () => {
    const userImage = props.user?.aThumbnail;
    if (!userImage) {
      return <AccountCircleIcon style={{ color: "gray", border: "none" }} />;
    }
    return (
      <Image
        className="w-8 h-8 rounded-full"
        alt="user photo"
        src={userImage!}
        width={32}
        height={32}
      />
    );
  };

  const renderSearch = () => {
    return (
      <div className="pl-8">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const query = (
              document.getElementById("default-search") as HTMLInputElement
            )?.value;
            if (getCurrentPath().includes("/riders")) {
              router.push("/riders/search/" + query);
            } else {
              router.push("/horses/search/" + query);
            }
          }}
        >
          <SearchBar />
        </form>
      </div>
    );
  };

  const renderNav = () => {
    return (
      <div className="hidden w-full md:block md:w-auto" id="navbar-default">
        <ul className="flex flex-col text-light-brown  md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 ">
          <NavItems showIcon={true} />
        </ul>
      </div>
    );
  };

  const renderAddActivityButton = () => {
    return (
      <div className="hidden lg:block">
        <AddActivityButton
          onClick={() => {
            router.push("/activity/add/");
          }}
        />
      </div>
    );
  };

  return (
    <nav className="bg-white px-2 sm:px-4 py-2.5  dark:bg-white w-full shadow-md">
      <div className=" flex flex-wrap items-center justify-between w-full">
        <div className="flex-cols flex">
          <Link href="/" className="flex items-center">
            <Image
              src="/logo.png"
              className=" mr-3 h-9"
              alt="HorseDay Logo"
              width={36}
              height={1}
            />
            {/* <span className="self-center text-xl font-semibold whitespace-nowrap dark:light-brown text-light-brown">
              HorseDay
            </span> */}
          </Link>
          {renderSearch()}
        </div>
        {renderNav()}

        <div className="flex flex-row">
          {renderAddActivityButton()}
          {/* <div className="bg-black rounded-full"> */}
          <div
            onClick={() => {
              router.push("/chat");
            }}
          >
            <Message
              className="mt-2 cursor-pointer hidden md:block"
              style={{ color: lightBrown }}
            />
          </div>
          {/* </div> */}
          <button
            type="button"
            className="text-sm  rounded-full ml-4"
            id="user-menu-button"
            aria-expanded="false"
            data-dropdown-toggle="user-dropdown"
            data-dropdown-placement="bottom"
          >
            <Link href="/settings">
              <span className="sr-only">Open user menu</span>
              {renderUserImage()}
            </Link>
          </button>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state: IStore) => {
  const { user } = state.userState || undefined;

  return { user };
};

export default connect(mapStateToProps)(Navbar);
