import { SafetyTrackingTeam } from "@/openapi";
import { _ } from "@/pages/_app";
import { formatDate } from "@/utils/dateUtils";
import { captureException } from "@/utils/devUtils";
import { safetyTrackingApi } from "@/utils/NetworkUtils";
import { Widgets } from "@mui/icons-material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Spinner from "../misc/Spinner";
import UserAvatar from "../user/userAvatar";

const RidingBuddyWidget = () => {
  const [teams, setTeams] = useState(
    undefined as SafetyTrackingTeam[] | undefined
  );
  const router = useRouter();

  const fetchMyTeams = async () => {
    try {
      var res = await safetyTrackingApi.safetyTrackingTeamsWhereViewerList();
      res = res.sort(
        (a, b) =>
          (b.dtLastMove ?? new Date(2021, 1, 1)).getTime() -
          (a.dtLastMove ?? new Date(2021, 1, 1)).getTime()
      );
      setTeams(res ?? []);
    } catch (e) {
      captureException(e);
    }
  };

  useEffect(() => {
    fetchMyTeams();
  }, []);

  const ridingBuddyItem = (team: SafetyTrackingTeam) => {
    const renderLastActivity = () => {
      if (!team.dtLatestPoint) {
        return <span>{_("Never been active")}</span>;
      }
      return (
        <span>
          {_("Last activity")} {formatDate(team.dtLatestPoint)}
        </span>
      );
    };

    return (
      <div
        className="mt-4 text-sm cursor-pointer"
        key={"ridingbuddy-" + team.user.uid}
        onClick={() => {
          if (team.token === null || !team.isCurrentlyTracking) {
            router.push("/riders/" + team.user.uid);
            return;
          }
          router.push("/ridingbuddy/token/" + team.token);
        }}
      >
        <div className="flex flex-row items-center">
          <UserAvatar user={team.user} radius={32} />
          <div className="flex flex-col ml-4">
            <span className="ml-0">{team.user.name?.split(" ")[0]}</span>
            <span className="text-xs font-medium">
              {team.isCurrentlyTracking ? (
                <span className=" text-burnt-orange">{_("LIVE NOW")}</span>
              ) : (
                renderLastActivity()
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  if (!teams) {
    return <></>;
  }

  if (teams.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-4 text-sm m-2">
        <div>{_("You don't have any riding buddies")}</div>
        <div className="text-xs text-stone-gray">
          {_("You can add riding buddies in the app")}
        </div>
      </div>
    );
  }

  return (
    <div className="m-2 p-4 -z-10 shadow-lg rounded-lg bg-white">
      <div className="pb-2 text-md font-medium">{_("Riding buddies")}</div>
      <hr />
      {teams.map((e) => {
        return ridingBuddyItem(e);
      })}
    </div>
  );
};

export default RidingBuddyWidget;
