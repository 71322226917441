import { HorsedayIconProps } from "./IconProps";

const HorseIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  return (
    <svg
      className={props.className}
      width={props.width ?? "32"}
      height={props.height ?? "33"}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9289 8.8727C23.609 10.9755 25.7201 11.2971 25.7201 11.2971C26.2063 12.868 28.0102 12.4475 28.0102 12.4475C28.4196 11.0002 28.0998 7.41311 28.0998 7.41311L29.0338 6.25038C29.0338 6.25038 25.1025 3.96707 21.2003 6.47807C19.0636 7.8387 18.5298 10.122 18.5298 10.122"
        stroke="#2CA6A4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.80004 17.9995C5.36031 12.5995 8.93276 10.2593 12.3872 10.2593C15.2019 10.2593 15.9184 10.2593 18.4388 10.2593"
        stroke="#2CA6A4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8128 26.9194L5.77441 22.1077C5.77441 22.1077 11.3527 20.6852 13.9115 15.7622"
        stroke="#2CA6A4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1812 17.2471H20.96L26.487 18.6696L23.7747 22.7144"
        stroke="#2CA6A4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8369 26.919L20.6665 17.2461C20.6665 17.2461 21.6004 12.3354 24.1337 10.7026"
        stroke="#2CA6A4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2495 19.5967L11.3647 23.1467L14.141 26.9194"
        stroke="#2CA6A4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.2002 16.1968C3.35372 13.9703 5.41548 10.3062 11.3647 10.3062"
        stroke="#2CA6A4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default HorseIcon;
