import { HorsedayIconProps } from "./IconProps";

const RidersIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  return (
    <svg
      width={props.width ?? "24"}
      height={props.height ?? "24"}
      fill={props.fill ?? "none"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9938 15.0669L14.1567 12.9403L3 11.3438"
        stroke={stroke}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.80859 8.94501C6.34498 5.13355 9.62637 2.19922 13.583 2.19922C17.9182 2.19922 21.4331 5.7141 21.4331 10.0493C21.4331 11.1347 21.2122 12.1696 20.8147 13.1036"
        stroke={stroke}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9.14844H8.48372"
        stroke={stroke}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9375 1.89124V1.5"
        stroke={stroke}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7188 22.875L12.9923 21.1434L14.4688 15L19.3438 16.8747"
        stroke={stroke}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default RidersIcon;
