import { PublicUser, SimpleUser, User } from "@/openapi";
import { _ } from "@/pages/_app";
import { isPremium } from "@/utils/userUtils";

interface IPremiumBadge {
  user: SimpleUser | User | PublicUser;
}

const PremiumBadge = ({ user }: IPremiumBadge) => {
  if (isPremium(user)) {
    return (
      <div className="rounded-full bg-lightest-gray p-1 text-xs text-stone-gray">
        {_("Premium")}
      </div>
    );
  }
  return (
    <div className="rounded-full bg-lightest-gray p-1 text-xs text-stone-gray">
      {_("Free account")}
    </div>
  );
};

export default PremiumBadge;
