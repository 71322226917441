interface TextInputProps {
  placeholder?: string;
  label?: string;
  onChange: (value: string) => any;
  type?: string;
  onEnter?: () => void;
  value?: string;
  className?: string;
  onClick?: () => void;
}

const TextInput = (props: TextInputProps) => {
  return (
    <div
      className={"mb-4 " + props.className}
      // onClick={props.onClick ? () => props.onClick!() : undefined}
    >
      {props.label && (
        <label
          htmlFor={props.label}
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          {props.label}
        </label>
      )}
      <input
        onClick={
          props.onClick
            ? (e) => {
                e.preventDefault();
                e.stopPropagation();
                props.onClick!();
              }
            : undefined
        }
        onKeyDown={
          !props.onEnter
            ? undefined
            : (e) => {
                if (e.key === "Enter") {
                  props.onEnter!();
                }
              }
        }
        value={props.value}
        type={props.type}
        id={props.label}
        className=" appearance-none pt-4 pb-4 rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  );
};

export default TextInput;
