import { CSSProperties, useEffect, useRef, useState } from "react";
import { clsx } from "clsx";
import { devPrint } from "@/utils/devUtils";

interface IDropdownButton<T> {
  onChange: (value: T) => void;
  items: { title: string | JSX.Element; value: T }[];
  title?: string;
  selected: T | undefined;
  color?: string;
  className?: string;
  child?: JSX.Element;
  style?: CSSProperties;
  key?: String;
}

const DropdownButton = <T,>(props: IDropdownButton<T>) => {
  const [open, setOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const toggleDropdown = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current!.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("click", toggleDropdown);
    () => document.removeEventListener("click", toggleDropdown);
  }, []);
  let selectedTitle = "" as string | JSX.Element;
  for (var item of props.items) {
    if (item.value === props.selected) {
      selectedTitle = item.title;
    }
  }
  return (
    <div className="relative" ref={dropdownRef}>
      {props.child ? (
        <div
          onClick={() => {
            setOpen(!open);
          }}
        >
          {props.child}
        </div>
      ) : (
        <button
          style={
            props.style ?? {
              // minWidth: 200,
              backgroundColor: props.color,
              color: props.color === "white" ? "black" : undefined,
              borderBottom:
                props.color === "white" ? "1px black solid" : undefined,
            }
          }
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown-btn"
          onClick={() => {
            setOpen(!open);
          }}
          className={clsx(
            props.className ??
              "text-white bg-light-brown focus:outline-none focus:ring-dark-brown font-medium  text-sm px-5 py-2.5 text-center inline-flex items-center"
          )}
          type="button"
        >
          <div className="flex flex-row items-center justify-between w-full">
            <div className="text-sm flex flex-col text-left">
              <span>{props.title}</span>
              <span className="font-light text-xs">{selectedTitle}</span>
            </div>
            <svg
              className="w-2.5 h-2.5 ml-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </div>
        </button>
      )}
      <div
        id="dropdown-btn"
        style={{
          display: open ? "block" : "none",
          maxHeight: 200,
          overflowY: "scroll",
          position: "absolute",
          width: "100%",
        }}
        className="z-10 bg-white divide-y rounded-lg shadow "
      >
        <ul
          className="py-2 text-sm text-gray-800"
          aria-labelledby="dropdownDefaultButton"
        >
          {props.items.map((e, i) => {
            return (
              <li
                key={
                  (props.key ?? "") +
                  (props.title ?? "title") +
                  "dropdown" +
                  e.title +
                  i
                }
              >
                <a
                  href="#"
                  className={
                    "block px-4 py-2  hover:text-black hover:bg-lightest-gray "
                  }
                  onClick={(event) => {
                    event.preventDefault();
                    props.onChange(e.value);
                    setOpen(false);
                  }}
                >
                  {e.title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DropdownButton;
