import { _ } from "@/pages/_app";
import {
  differenceInDays,
  differenceInMinutes,
  differenceInSeconds,
  format,
} from "date-fns";
import { devPrint } from "./devUtils";

export const isSameDate = (a?: Date, b?: Date) => {
  if (!a || !b) return false;
  return (
    a.getDate() === b.getDate() &&
    a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear()
  );
};

// don't use!
export const formatDate = (date: Date | string, format?: string): string => {
  if (typeof date === "string") {
    var d = new Date(date);
    d.toLocaleString(undefined, { dateStyle: "short" });
  }
  return date.toLocaleString(undefined, { dateStyle: "long" });
};

const getWeek = (date: Date) => {
  var dt = new Date(date.getFullYear(), 0, 1);
  return Math.ceil(
    ((date.getMilliseconds() - dt.getMilliseconds()) / 86400000 +
      dt.getDay() +
      1) /
      7
  );
};

const daysInMonth = (month: number, year: number): number => {
  return new Date(year, month + 1, 0).getDate();
};

export const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const getMonthDays = (month: number, year: number): CalendarDay[] => {
  const days = daysInMonth(month, year);
  const monthDays: CalendarDay[] = [];

  for (let i = 1; i <= days; i++) {
    monthDays.push({
      date: i,
      isCurrentMonth: true,
      dayDate: new Date(year, month, i, 8),
    });
  }

  const firstDay = new Date(year, month, 1).getDay();
  const lastDay = new Date(year, month, days).getDay();
  // add days from previous month
  for (let i = 0; i < firstDay; i++) {
    const dayInMonth = daysInMonth(month - 1, year) - i;
    monthDays.unshift({
      date: dayInMonth,
      isCurrentMonth: false,
      dayDate: new Date(year, month - 1, dayInMonth, 8),
    });
  }

  // add days from next month
  for (let i = 1; i < 7 - lastDay; i++) {
    monthDays.push({
      date: i,
      isCurrentMonth: false,
      dayDate: new Date(year, month + 1, i, 8),
    });
  }

  return monthDays;
};

export type CalendarDay = {
  date: number;
  dayDate?: Date;
  isCurrentMonth: boolean;
};

export const dateInList = (date: Date, dates: Date[]) => {
  for (var item of dates) {
    if (isSameDate(date, item)) {
      return true;
    }
  }
  return false;
};

/// date with no time
export const simpleDateToString = (date?: string) => {
  if (!date) {
    return undefined;
  }
  const d = new Date(date);
  return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
};

export const stringToDate = (date?: string) => {
  if (!date) {
    return undefined;
  }
  const d = new Date(date);
  return d;
};

export const getNow = () => new Date();

export const dateToReadable = (_date: Date): string => {
  var now = new Date();
  var diffMins = differenceInMinutes(now, _date);

  if (diffMins < 0) {
    // in future
    return format(_date, "dd/MM/yyyy HH:mm");
  }

  if (diffMins < 1) {
    return _("Just now");
  }
  if (diffMins < 5) {
    return _("Five minutes ago");
  }
  if (diffMins < 30) {
    return _("Thrity minutes ago");
  }

  var dayDiff = differenceInDays(now, _date);
  if (dayDiff < 1) {
    return _("Yesterday at") + " " + format(_date, "HH:mm");
  }
  if (dayDiff < 7) {
    return (
      _(format(_date, "eeee")) + " " + _("at") + " " + format(_date, "HH:mm")
    );
  }
  return format(_date, "dd/MM/yyyy HH:mm");

  // todo rest:
  // one week
};
