import {
  ActivityTypeEnum,
  Horse,
  SimpleHorse,
  SimpleHorseActivity,
  SimpleUserActivity,
} from "@/openapi";
import { _ } from "@/pages/_app";
import {
  fetchAllHorsesActivitySummary,
  fetchHorsesActivitySummary,
  fetchUserActivitySummary,
} from "@/redux/api_methods";
import { CalendarDay } from "@/utils/dateUtils";
import {
  activitiesPlural,
  activityTypeColor,
  activityTypeToString,
} from "@/utils/stringUtils";
import clsx from "clsx";
import { format } from "date-fns";
import { useRouter } from "next/router";
import { useState } from "react";
import Modal from "react-modal";
import AddActivityComponent from "../activities/forms/AddActivityComponent";
import AddActivityButton from "../buttons/AddActivityButton";
import HorseAvatar from "../misc/HorseAvatar";
import UserAvatar from "../user/userAvatar";

interface IActivityCalendarDayProps {
  day: CalendarDay;
  date: Date;
  activities?: SimpleUserActivity[] | SimpleHorseActivity[];
  onClick?: () => {};
  horse?: Horse | SimpleHorse;
}

export const addActivityModalStyles: Modal.Styles = {
  content: {
    top: "20px",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, 0)",
    maxHeight: "90vh",
    overflowY: "scroll",
  },
};

export const customModalStyles: Modal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "400px",
    overflowY: "scroll",
  },
};

const ActivityCalendarDay = ({ day, ...props }: IActivityCalendarDayProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isAddingActivity, setIsAddingActivity] = useState(false);
  const router = useRouter();

  const renderDots = () => {
    const actTypes: ActivityTypeEnum[] = [];
    for (var item of props.activities ?? []) {
      if (!actTypes.includes(item.type)) {
        actTypes.push(item.type);
      }
    }
    return (
      <div
        className="flex flex-row justify-between ml-2 mr-2 absolute "
        style={{
          left: "0",
        }}
      >
        {actTypes.slice(0, 4).map((e, i) => {
          return (
            <div key={"dot" + i + day + "act1"} className="">
              <div
                key={"dot" + i + day + "act"}
                className=" rounded-full justify-center items-center"
                style={{
                  width: 8,
                  height: 8,
                  backgroundColor: activityTypeColor(e),
                  // position: "relative",
                  position: "absolute",
                  left: i * 4,
                }}
              >
                <div
                // style={{ position: "absolute", left: "0%", top: "15%" }}
                >
                  {/* {activityTypeIcon(e.type, "white", 8)} */}
                </div>
                {/* <TrackTrainingIcon fill={lightBrown} height={8} width={8} /> */}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setIsOpen(false)}
        style={customModalStyles}
        contentLabel=" Modal"
      >
        <div className="flex flex-row justify-between items-center pb-4">
          <AddActivityButton
            onClick={() => {
              setIsAddingActivity(true);
            }}
          />

          <div className="text-sm text-stone-gray font-medium text-right">
            {format(props.date, "dd/MM/yy")}
          </div>
        </div>
        <div className="text-xs">
          {props.activities?.length}{" "}
          {activitiesPlural(props.activities?.length)}
        </div>
        <hr className="m-2" />

        {props.activities?.map(
          (e: SimpleHorseActivity | SimpleUserActivity, i) => {
            return (
              <div
                key={e.id + "simple_act"}
                className="pb-4 cursor-pointer"
                onClick={() => {
                  if (e.parentActivity) {
                    router.push("/activity/" + e.parentActivity);
                    return;
                  }
                  router.push("/activity/" + e.id);
                }}
              >
                <div className="text-dark-brown text-sm font-bold">
                  {activityTypeToString(e.type)}
                </div>
                {e instanceof SimpleHorseActivity ? (
                  <div>
                    <div className="flex flex-row text-sm items-center text-stone-gray">
                      <UserAvatar user={e.actor} />
                      <div className="ml-2">{e.actor?.name}</div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row text-sm items-center text-stone-gray">
                    <HorseAvatar simpleHorse={e.horse} radius={32} />
                    <div className="flex flex-col ml-2">
                      <div className="">{e.horse?.name ?? _("No horse")}</div>
                      <div className="text-xs">
                        {format(e.dtPerformed!, "hh:mm")}
                      </div>
                    </div>
                  </div>
                )}

                {i !== props.activities!.length - 1 && <hr className="mt-2" />}
              </div>
            );
          }
        )}
      </Modal>
      <div
        onClick={() => {
          setIsOpen(true);
          if (props.activities && props.activities.length > 0) {
          }
          if (props.onClick) props.onClick();
        }}
        className={clsx(
          "w-full p-2 rounded-full text-center cursor-pointer relative",
          day.isCurrentMonth ? " text-black" : "text-gray-400"
        )}
      >
        {day.date}
        {renderDots()}
      </div>
      <Modal
        ariaHideApp={false}
        style={addActivityModalStyles}
        isOpen={isAddingActivity}
        onRequestClose={() => setIsAddingActivity(false)}
      >
        <AddActivityComponent
          onClose={(didSave: boolean) => {
            if (didSave) {
              if (props.horse) {
                fetchHorsesActivitySummary(
                  props.date.getMonth(),
                  props.date.getFullYear(),
                  props.horse.faedingarnumer,
                  true
                );
                fetchAllHorsesActivitySummary(
                  props.date.getMonth(),
                  props.date.getFullYear(),
                  true
                );
              } else {
                fetchUserActivitySummary(
                  props.date.getMonth(),
                  props.date.getFullYear(),
                  true
                );
              }
            }
            setIsAddingActivity(false);
          }}
          horse={props.horse}
          date={props.date}
        />
      </Modal>
    </>
  );
};

export default ActivityCalendarDay;
