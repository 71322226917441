import { HorsedayIconProps } from "./IconProps";

const HorseIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  return (
    <svg
      //   className="-light-brown"
      width={props.width ?? "23"}
      height={props.height ?? "36"}
      viewBox="0 0 23 36"
      fill={props.fill ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2651 18.89L15.1951 27.55C16.0251 28.17 16.3651 29.25 16.0551 30.23L14.6751 34.6H11.4851H8.29508L6.91508 30.23C6.60508 29.24 6.94508 28.17 7.77508 27.55L5.70508 18.89"
        stroke={stroke}
        strokeWidth="1.69"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.994 11.34L21.394 8.37998L20.654 1.47998L15.334 7.16998"
        stroke={stroke}
        strokeWidth="1.69"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.00547 11.26L1.60547 8.3L2.34547 1.39999L7.66547 7.09"
        stroke={stroke}
        strokeWidth="1.69"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5151 6.59998C11.3051 11.33 7.45508 15.12 2.70508 15.23"
        stroke={stroke}
        strokeWidth="1.69"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9338 6.87997C14.6438 13.33 9.40383 18.49 2.92383 18.64"
        stroke={stroke}
        strokeWidth="1.69"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default HorseIcon;
