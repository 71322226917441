import { PublicUser } from "@/openapi";
import { _ } from "@/pages/_app";
import UserActivityCalendar from "../calendar/userActivityCalendar";
import TwoColumnHorses from "../horse/ColumnHorses";
import PremiumBadge from "../misc/PremiumBadge";
import TrainingSummaryChart from "../training/trainingSummaryChart";
import UserAvatar from "./userAvatar";

interface IUserProfileProps {
  user: PublicUser;
  oneColumn?: boolean;
  simpleHorseCards?: boolean;
}
const UserProfile = ({ user, ...props }: IUserProfileProps) => {
  const renderFollowerFollowing = () => {
    return (
      <div className="text-sm m-auto flex flex-row  justify-between">
        <div className=" text-center  ">
          <div>{user.allHorses?.length}</div>
          <div className="font-bold">{_("Horses")}</div>
        </div>
        <div className="text-center pl-4">
          <div>{user.numFollowers}</div>
          <div className="font-bold">{_("Followers")}</div>
        </div>
        <div className=" text-center pl-4">
          <div>{user.numFollowers}</div>
          <div className="font-bold">{_("Followers")}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen ">
      <div className="relative ">
        <div className="bg-white min-w-0 rounded-lg shadow-lg px-4 py-5 sm:p-6">
          <div className="flex flex-row grid-rows-4 pb-4 w-full gap-4 justify-between">
            <div className="row-span-1">
              <UserAvatar
                user={user}
                radius={props.simpleHorseCards ? undefined : 100}
              />
            </div>
            <div className="row-span-3">{renderFollowerFollowing()}</div>
          </div>
          <div className="flex flex-row">
            <h3 className="text-lg leading-6 font-medium text-gray-900"></h3>
            {user.name}
            <span className="ml-4">
              <PremiumBadge user={user} />
            </span>
          </div>
          {!props.simpleHorseCards && (
            <div className="pb-4">
              <p className="text-sm">{user.description}</p>
              {user.role?.split("/n").map((e) => {
                return (
                  <span key={"role-" + e} className="text-sm mr-4">
                    {_(e)}
                  </span>
                );
              })}
            </div>
          )}
          <br />
          <TrainingSummaryChart publicUser={user} />
          <br />
          {props.simpleHorseCards && <UserActivityCalendar />}
          <br />
          <TwoColumnHorses
            horses={user.allHorses}
            numColumns={props.oneColumn ? 1 : 2}
            simpleCards={props.simpleHorseCards}
          />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
