import { _ } from "@/pages/_app";
import { getCurrentPath, hasWindow } from "@/utils/devUtils";
import { t } from "@/utils/translation";

interface ISearchBar {
  id?: string;
}

const SearchBar = (props: ISearchBar) => {
  const placeholder = getCurrentPath().includes("riders")
    ? _("Search Riders")
    : _("Search horses");
  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg
          aria-hidden="true"
          className="w-5 h-5 text-dark-brown "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </div>
      <input
        type="search"
        id={props.id ?? "default-search"}
        className="block w-full p-4 pl-10 text-sm text-primary-brown  border-stone-gray rounded-full bg-lightest-gray  placeholder:text-primary-brown "
        style={{ outlineWidth: 0 }}
        placeholder={placeholder}
        required
      />
    </div>
  );
};

export default SearchBar;
