import { SimpleHorse } from "@/openapi";
import { _ } from "@/pages/_app";
import HorseIcon from "../icons/HorseIcon";
import SimpleHorseCard from "./SimpleHorseCard";
import { clsx } from "clsx";
import { devPrint } from "@/utils/devUtils";

interface IColumnHorses {
  horses: SimpleHorse[];
  numColumns: number;
  simpleCards?: boolean;
  title?: string;
  noHideTitle?: boolean;
  onClick?: (horse: SimpleHorse) => void;
  trailingIcon?: JSX.Element;
  onClickTrailingIcon?: (horse: SimpleHorse) => void;
}

const ColumnHorses = (props: IColumnHorses) => {
  var cols = "grid-cols-" + (props.numColumns ?? 2);
  return (
    <div>
      {!props.noHideTitle && (
        <>
          <span className="flex flex-row text-sm font-medium pb-2">
            <HorseIcon height={18} className="pr-4" />{" "}
            {props.title ?? _("Horses")}
          </span>
          <hr />
        </>
      )}
      <div
        className={clsx(
          "justify-start grid",
          "grid-cols-1 lg:grid-cols-3 md:grid-cols-2 xl:gird-cols-4"
        )}
      >
        {props.horses.map((e) => {
          return (
            <div
              key={"horse-" + e.faedingarnumer}
              style={{ maxWidth: 300 }}
              className={clsx(
                props.trailingIcon
                  ? "flex flex-row justify-between items-center"
                  : ""
              )}
            >
              <SimpleHorseCard
                onClick={
                  props.onClick
                    ? (e) => {
                        props.onClick!(e);
                      }
                    : undefined
                }
                simpleHorse={e}
                onlyNameImage={props.simpleCards}
              />
              {props.trailingIcon ? (
                <div
                  className="cursor-pointer"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (props.onClickTrailingIcon)
                      props.onClickTrailingIcon!(e);
                  }}
                >
                  {props.trailingIcon}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ColumnHorses;
