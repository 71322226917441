import { HorsedayIconProps } from "./IconProps";

const HorseIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  return (
    <svg
      className={props.className}
      width={props.width ?? "32"}
      height={props.height ?? "33"}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.87864 19.2651C5.07873 13.8651 8.90173 11.021 12.227 11.021C15.0515 11.021 16.3611 11.021 18.8903 11.021"
        stroke="#E483A7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.2002 26.6218L6.03757 22.9749C6.03757 22.9749 12.1232 23.6238 13.7537 17.394"
        stroke="#E483A7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7681 18.3525H21.7153H25.0405C26.517 18.3525 27.4029 20.0008 26.594 21.2468L25.7338 22.5965"
        stroke="#E483A7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5415 28.5163L19.3775 18.367C19.3775 18.367 26.182 18.367 24.3332 9.76221"
        stroke="#E483A7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1108 20.834L13.1123 24.5459L16.2706 28.5173"
        stroke="#E483A7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.692 8.29475C24.257 10.4492 26.3497 9.9041 26.3497 9.9041C27.441 11.2149 28.9303 10.0858 28.9303 10.0858C28.712 8.52836 26.9274 5.20584 26.9274 5.20584L27.3126 3.70032C27.3126 3.70032 22.3953 3.55755 19.9816 7.63284C19.1728 9.00857 18.9545 10.2545 18.916 11.0073"
        stroke="#E483A7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.2793 17.4633C3.43336 15.1272 4.98625 11.0063 10.9563 11.0063H14.1917"
        stroke="#E483A7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default HorseIcon;
