import { HorsedayIconProps } from "./IconProps";

const FeedIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  return (
    <svg
      width={props.width ?? "24"}
      height={props.height ?? "24"}
      fill={props.fill ?? "none"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.25"
        y="15"
        width="18.75"
        height="5.25"
        rx="2.625"
        stroke={stroke}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.25"
        y="3.75"
        width="18.75"
        height="5.25"
        rx="2.625"
        stroke={stroke}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 12L18 12"
        stroke={stroke}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default FeedIcon;
