import TextInput from "@/components/inputs/TextInput";
import { HorseActivity, Medical } from "@/openapi";
import { _ } from "@/pages/_app";
import { TextField } from "@mui/material";

interface IAddMedicalComponent {
  activity: HorseActivity;
  onChange: (medical: Medical) => void;
}

const AddMedicalComponent = (props: IAddMedicalComponent) => {
  return (
    <div>
      <TextInput
        // value={props.activity.medical?.veterinarian}
        label={_("Veterinarian")}
        onChange={(e) => {
          const newMedical = { ...props.activity.medical } as Medical;
          newMedical!.veterinarian! = e;
          props.onChange(newMedical);
        }}
      />
    </div>
  );
};

export default AddMedicalComponent;
