import { _ } from "@/pages/_app";
import { isPremium } from "@/utils/userUtils";
import GallopIcon from "../icons/GallopIcon";
import PaceIcon from "../icons/PaceIcon";
import ToltIcon from "../icons/ToltIcon";
import TrotIcon from "../icons/TrotIcon";
import WalkIcon from "../icons/WalkIcon";
import { DataDNT } from "./trainingSummaryChart";

interface IGaitSummaryWidget {
  data?: DataDNT;
}

interface IGaitSummaryItem {
  icon: JSX.Element;
  title: string;
  ratio: number;
}

const GaitSummaryWidget = ({ data, ...props }: IGaitSummaryWidget) => {
  if (!isPremium()) {
    return <></>;
  }

  const renderItem = (item: IGaitSummaryItem) => {
    return (
      <div
        key={"gaitsum" + item.title}
        className="flex flex-col text-center text-sm"
      >
        <div>{item.icon}</div>
        <div style={{ fontSize: 10 }} className="text-stone-gray">
          {item.title}
        </div>
        <div>{((item.ratio * 10000) / 100).toFixed(0)}%</div>
      </div>
    );
  };
  if (!data) {
    return <div></div>;
  }

  const distances = [
    // data.standingDistance,
    data.walkDistance,
    data.toltDistance,
    data.trotDistance,
    data.gallopDistance,
    data.paceDistance,
  ];

  var totalDistance = distances.reduce((e, v) => e! + v!, 0) ?? 1;
  if (totalDistance === 0) {
    totalDistance = 1;
  }
  const items = [
    {
      icon: <WalkIcon />,
      title: _("Walk"),
      ratio: distances[0]! / totalDistance,
    },
    {
      icon: <ToltIcon />,
      title: _("Tolt"),
      ratio: distances[1]! / totalDistance,
    },
    {
      icon: <TrotIcon />,
      title: _("Trot"),
      ratio: distances[2]! / totalDistance,
    },
    {
      icon: <GallopIcon />,
      title: _("Gallop"),
      ratio: distances[3]! / totalDistance,
    },
    {
      icon: <PaceIcon />,
      title: _("Pace"),
      ratio: distances[4]! / totalDistance,
    },
  ];

  return (
    <div>
      <div className="flex flex-row justify-between">
        {items.map((e) => {
          return renderItem(e);
        })}
      </div>
    </div>
  );
};

export default GaitSummaryWidget;
