import { lightBrown } from "@/utils/colors";
import { isSameDate } from "@/utils/dateUtils";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { customModalStyles } from "../calendar/activityCalendarDay";
import Calendar from "../calendar/calendar";
import CloseButton from "./CloseButton";
import TimePicker from "./TimePicker";

interface IDatePicker {
  onDateChange: (value: Date) => void;
  selectedDate?: Date;
  noTime?: boolean;
}

const DatePicker = (props: IDatePicker) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth());
  useEffect(() => {
    if (props.selectedDate) {
      setSelectedDate(props.selectedDate);
      setMonth(props.selectedDate.getMonth());
    }
  }, [props.selectedDate]);

  return (
    <div>
      <div
        style={{ borderBottom: "1px gray solid" }}
        className="cursor-pointer"
        onClick={() => {
          setOpen(!open);
        }}
      >
        {format(selectedDate, props.noTime ? "dd/MMM/yy" : "dd/MMM/yy HH:mm")}
      </div>
      <Modal
        ariaHideApp={false}
        style={customModalStyles}
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
        }}
      >
        <div className="w-80">
          <div className="flex flex-row justify-between">
            {props.noTime ? (
              <div></div>
            ) : (
              <TimePicker
                selectedDate={selectedDate}
                onChange={(hour, minute) => {
                  let newDate = selectedDate;

                  newDate.setHours(hour);
                  newDate.setMinutes(minute);
                  setSelectedDate(newDate);
                }}
              />
            )}
            <CloseButton
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>

          <Calendar
            onMonthChange={(e) => {
              let newDate = selectedDate;

              newDate.setMonth(selectedDate.getMonth() + (e ? 1 : -1));
              setSelectedDate(newDate);
              setMonth(newDate.getMonth());
            }}
            month={month}
            year={selectedDate.getFullYear()}
            dayBuilder={(day, date) => {
              const isSelected =
                isSameDate(date, selectedDate) && day.isCurrentMonth;

              return (
                <div
                  onClick={() => {
                    props.onDateChange(date);
                    setOpen(false);
                  }}
                  className="text-center rounded-xl cursor-pointer"
                  style={{
                    backgroundColor: !isSelected ? "white" : "black",
                    color: isSelected
                      ? "white"
                      : day.isCurrentMonth
                      ? "black"
                      : lightBrown,
                  }}
                >
                  {day.date}
                </div>
              );
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DatePicker;
