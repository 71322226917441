import { restDayBlue } from "@/utils/colors";
import { useState } from "react";
import DropdownButton from "./DropdownButton";

interface ITimePicker {
  onChange: (hour: number, minute: number) => void;
  selectedDate: Date;
}

const TimePicker = (props: ITimePicker) => {
  let selectedHour = props.selectedDate.getHours();
  if (selectedHour < 8) {
    selectedHour = 8;
  }
  const [selected, setSelected] = useState(selectedHour);
  const times = [];
  for (let i = 8; i < 23; i++) {
    times.push({ value: i, title: i + ":00" });
  }

  return (
    <DropdownButton<number>
      color={"white"}
      title={"Time"}
      items={times}
      selected={selected}
      onChange={(e) => {
        setSelected(e);
        props.onChange(e, 0);
      }}
    />
  );
};

export default TimePicker;
