import { HorsedayIconProps } from "./IconProps";

const HorseIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  return (
    <svg
      className={props.className}
      width={props.width ?? "32"}
      height={props.height ?? "33"}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2948 8.98684C25.0913 11.1533 27.2157 11.3686 27.2157 11.3686C27.7881 12.9523 29.5436 12.4202 29.5436 12.4202C29.8616 10.9252 29.34 7.26383 29.34 7.26383L30.2051 6.02225C30.2051 6.02225 25.9274 3.98349 22.2002 6.75805C20.1521 8.27835 20.0029 10.6592 20.0029 10.6592"
        stroke="#F0C05A"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0018 10.6567C17.4958 10.6567 14.4682 10.6567 11.6696 10.6567C7.92968 10.6567 5.7417 13.7227 5.7417 16.0285C5.7417 18.2583 6.87385 21.3875 11.1481 21.3875V24.8209L14.2797 27.6356"
        stroke="#F0C05A"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7148 17.8184H20.7269C23.3728 17.8184 24.0216 16.8936 24.0216 16.8936"
        stroke="#F0C05A"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.5172 27.7247C29.5172 27.7247 20.9942 17.8174 25.5101 10.2666"
        stroke="#F0C05A"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.67627 27.725C2.67627 27.725 5.5639 24.3676 7.07768 20.0854"
        stroke="#F0C05A"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5063 16.272C14.5063 16.272 17.1598 18.6385 14.2798 22.5985"
        stroke="#F0C05A"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7266 17.8193L21.6298 24.3946L18.6001 26.1999"
        stroke="#F0C05A"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.3999 17.1956C2.55255 14.9152 4.22713 10.7074 10.1423 10.7074L14.0222 10.6567"
        stroke="#F0C05A"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default HorseIcon;
