import { SimpleUserActivity } from "@/openapi";
import { _ } from "@/pages/_app";
import { fetchUserActivitySummary } from "@/redux/api_methods";
import { IStore } from "@/redux/store";
import { CalendarDay, isSameDate } from "@/utils/dateUtils";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import ActivityCalendarDay from "./activityCalendarDay";
import Calendar from "./calendar";

interface IUserActivityCalendarProps {
  userActivities: SimpleUserActivity[] | undefined;
}

const UserActivityCalendar = ({
  userActivities,
}: IUserActivityCalendarProps) => {
  const [date, setDate] = useState(new Date());

  // useEffect(() => {
  //   fetchUserActivitySummary(date.getMonth(), date.getFullYear());
  // }, []);

  useEffect(() => {
    fetchUserActivitySummary(date.getMonth(), date.getFullYear());
  }, [date]);

  const getActivitiesOnDay = (date: Date): SimpleUserActivity[] => {
    if (!userActivities) {
      return [];
    }
    const acts: SimpleUserActivity[] = [];

    for (let item of userActivities) {
      if (isSameDate(item.dtPerformed, date)) {
        acts.push(item);
      }
    }

    return acts;
  };

  return (
    <div style={{ maxWidth: "90%", margin: "auto" }}>
      <div className="text-stone-gray">{_("Monthly training overview")}</div>
      <Calendar
        onMonthChange={(next: boolean) => {
          if (next) {
            var nm = date.getMonth() + 1;
            var ny = date.getFullYear();
            setDate(new Date(ny, nm));
          } else {
            var nm = date.getMonth() - 1;
            var ny = date.getFullYear();
            setDate(new Date(ny, nm));
          }
        }}
        month={date.getMonth()}
        year={date.getFullYear()}
        dayBuilder={(day: CalendarDay, date: Date) => {
          if (!day.isCurrentMonth) {
            return undefined;
          }
          return (
            <ActivityCalendarDay
              date={date}
              day={day}
              activities={getActivitiesOnDay(date)}
            />
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: IStore) => {
  const userActivities = state.activityState.userActivitySummary;

  return { userActivities };
};

export default connect(mapStateToProps)(UserActivityCalendar);
