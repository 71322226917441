import Image from "next/image";
import Footer from "./footer";
import Navbar from "./navbar";

import { useAuth } from "@/auth";
import { _ } from "@/pages/_app";
import { devPrint } from "@/utils/devUtils";
import { showTestFeature } from "@/utils/userUtils";
import { Roboto } from "next/font/google";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Button from "./buttons/Button";
import RidingBuddyWidget from "./riding_buddy/RidingBuddyWidget";
import MyUserProfile from "./user/myUserProfile";

interface LayoutProps {
  children: JSX.Element;
  fullpage?: boolean;
}
const inter = Roboto({ subsets: ["latin"], weight: "400" });

export const getLatestRouteBeforeLogin = () => {
  return latestRouteBeforeLogin;
};

let latestRouteBeforeLogin = "/";

const Layout = (props: LayoutProps) => {
  const { auth, loading, isAuthenticated } = useAuth();
  const [showNotLoggedIn, setShowNotLoggedIn] = useState(false);
  const router = useRouter();

  useEffect(() => {
    devPrint("pathname", router.pathname);
    if (
      !loading &&
      router.pathname !== "/checkout" &&
      // !isTestEnv() &&
      !showTestFeature()
    ) {
      setShowNotLoggedIn(true);
      router.replace("/checkout");
    }
  }, [loading, isAuthenticated]);

  if (loading || !isAuthenticated) {
    return null;
  }

  if (!showTestFeature()) {
    // return <Login />;
    // return;
    // if(isAuthenticated){
    //   return <Checkout />
    // }
    // return <Login />
    return <div></div>;
    return (
      <div className="flex flex-col text-center h-screen pt-8 text-white">
        <Image
          src="/logo.png"
          className="ml-auto mr-auto "
          alt="HorseDay Logo"
          width={64}
          height={10}
        />
        <div>{_("HorseDay web app coming soon.")}</div>
        <div>
          <Button
            className="bg-stone-gray mt-8 hover:bg-stone-gray-hover"
            onClick={() => router.push("/checkout")}
          >
            {_("Go to checkout")}
          </Button>
        </div>
      </div>
    );
  }
  return (
    <>
      {/* <Sidebar /> */}
      <Navbar />
      <div
        className={
          "m-auto bg-lightest-gray " +
          (props.fullpage ? "" : "grid lg:grid-cols-4 md:grid-cols-1")
        }
      >
        {!props.fullpage && (
          <div
            className="col-span-1 mt-2 ml-2 hidden lg:block"
            // style={{ minHeight: "100vh", overflowY: "scroll" }}
          >
            <MyUserProfile />
          </div>
        )}
        <main
          className={"flex-grow col-span-2"}
          style={{
            maxWidth: props.fullpage ? undefined : 600,
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 10,
            paddingBottom: 60,
          }}
        >
          {showNotLoggedIn ? (
            <div className="text-center text-sm">
              <div className="pb-4">
                Not logged in, login to use the HorseDay web app
              </div>
              <Button
                onClick={() => {
                  latestRouteBeforeLogin = window.location.pathname;
                  console.log("latestRouteBeforeLogin", latestRouteBeforeLogin);
                  router.push("/login");
                }}
              >
                Login
              </Button>
              {/* <LoginButton /> */}
            </div>
          ) : (
            props.children
          )}
        </main>
        {!props.fullpage && (
          <div className="col-span-1 hidden lg:block">
            <RidingBuddyWidget />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Layout;
