import {
  Group,
  Horse,
  HorseForSale,
  HorsePhoto,
  HorseProfilePhoto,
  SearchHorse,
  SexEnumEnum,
  SimpleHorse,
} from "@/openapi";
import store from "@/redux/store";
import { countryEmoji } from "./stringUtils";

export const getVetrarFromFaedingarnumer = (id: string): string => {
  const year = id.substring(2, 6);
  return getVetrarFromBirthDay(new Date(parseInt(year), 1, 1)) + " v.";
};

export const getVetrarFromBirthDay = (birthday: Date) => {
  const nowYear = new Date().getFullYear();
  birthday = new Date(birthday.getFullYear(), 0, 1);
  const date1 = new Date(nowYear, 4, 1);
  const diff = dateDiffInDays(birthday, date1);
  // const    diffDays =
  // return .difference(birthday).inDays ~/ 365;
  return (diff / 365).toFixed(0);
};

function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const sexEnumToString = (e: SexEnumEnum | undefined): String => {
  if (!e) {
    return "Gelding"; // or unknown
  }
  switch (e) {
    case "MARE":
      return "Mare";
    case "GELDING":
      return "Gelding";

    case "STALLION":
      return "Stallion";
  }
};

export const horseToSimpleHorse = (horse: Horse | SearchHorse): SimpleHorse => {
  let t = new SimpleHorse();
  t.faedingarnumer = horse.faedingarnumer;
  t.name = horse.name;
  t.sexEnum = horse.sexEnum;
  if (horse instanceof Horse) {
    if (horse.photos.length > 0) {
      t.avatar = { ...horse.photos[0] } as HorseProfilePhoto;
    }
  } else {
  }
  return t;
};

export const simpleHorseToHorse = (horse: SimpleHorse): Horse => {
  let t = new Horse();
  t.faedingarnumer = horse.faedingarnumer;
  t.name = horse.name;
  t.sexEnum = horse.sexEnum;
  t.photos = horse.avatar ? [{ ...horse.avatar } as HorsePhoto] : []; // horse.photos.map((e) => ({ ...e } as HorsePhoto));
  return t;
};

export const getHorseInfo = (horse: Horse) => {
  let text = "";
  text += sexEnumToString(horse.sexEnum);
  text += " | " + horse.colorName;
  const date = new Date(horse.birthDate);
  const vetrar = getVetrarFromBirthDay(date);
  text += " | " + vetrar + "v.";
  // const vetrar = getVetrarFromBirthDay(horse.birthDate)
  if (horse.originCountry) {
    text +=
      " | " + horse.originCountry + " " + countryEmoji(horse.originCountry);
  }
  text += " | " + horse.faedingarnumer;
  text += " | " + horse.rfid;
  return text;
};

export const getDefaultForSale = (): HorseForSale => {
  const user = store.getState().userState.user;
  return {
    forSale: true,
    description: "",
    horseType: "OTHER",
    currency: "",
    contactPhone: user?.phone,
    contactPhoneCountryCode: user?.phoneCountryCode,
    price: 0,
    priceCategory: "UNDEFINED",
    contactEmail: user?.email,
    title: "",
  } as HorseForSale;
};

export const isOwner = (horse: Horse | SimpleHorse) => {
  const horses = store.getState().horsesState.simpleHorses;
  for (var item of horses ?? []) {
    if (item.faedingarnumer === horse.faedingarnumer) {
      return true;
    }
  }
  return false;
};

export const createDefaultStallionGroup = (horse: SimpleHorse) => {
  const group: Group = {
    id: -1,
    dtCreated: new Date(),
    horses: [],
    uid: "",
    users: [],
    name: "",
    groupType: "STALLION",
    stallionGroup: {
      id: -1,
      location: {},
      locationString: "",
      mares: [],
      stallion: horse,
    },
  };
  return group;
};

export const horseInList = (
  horse: SimpleHorse | Horse,
  horses: SimpleHorse[] | Horse[]
): boolean => {
  for (var item of horses) {
    if (item.faedingarnumer === horse.faedingarnumer) {
      return true;
    }
  }
  return false;
};

export const horseToSearchHorse = (horse: Horse): SearchHorse => {
  var sHorse = { ...horse } as SearchHorse;
  // todo fix
  sHorse.avatar =
    horse.photos.length > 0
      ? ({ ...horse.photos[0] } as HorseProfilePhoto)
      : undefined;
  return sHorse;
};
