import Button from "@/components/buttons/Button";
import CloseButton from "@/components/buttons/CloseButton";
import DatePicker from "@/components/buttons/DatePicker";
import DropdownButton from "@/components/buttons/DropdownButton";
import {
  ActivityTypeEnum,
  Horse,
  SimpleHorse,
  VisibilityEnum,
} from "@/openapi";
import { _ } from "@/pages/_app";
import store, { IStore } from "@/redux/store";
import {
  createDefaultActivity,
  savableActivityTypes,
  saveActivityToServer,
} from "@/utils/activityUtils";
import { horseToSimpleHorse } from "@/utils/horseUtils";
import { activityTypeColor, activityTypeToString } from "@/utils/stringUtils";
import { useRouter } from "next/router";
import { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import SimpleHorseCard from "../../horse/SimpleHorseCard";
import RadioPills from "../../inputs/radioPills";
import TextInput from "../../inputs/TextInput";
import AddMedicalComponent from "./AddMedicalComponent";

interface IAddActivityComponent {
  simpleHorses: SimpleHorse[] | undefined;
  date?: Date;
  onClose?: (didSave: boolean) => void;
  horse?: Horse;
}

const AddActivityComponent = (props: IAddActivityComponent) => {
  const [activity, setActivity] = useState(
    createDefaultActivity(
      props.date,
      !props.horse ? undefined : horseToSimpleHorse(props.horse)
    )
  );
  const [loadingSave, setLoadingSave] = useState(false);
  const router = useRouter();

  const handleSaveActivity = async () => {
    if (loadingSave) {
      return;
    }
    setLoadingSave(true);
    var res = await saveActivityToServer(activity);
    if (res) {
      toast.success("Activity saved!");
      if (props.onClose) {
        props.onClose(true);
      } else {
        router.push("/");
      }
    } else {
      toast.error("Error saving");
    }
    setLoadingSave(false);
  };

  const renderNotesInput = () => {
    return (
      <TextInput
        label={_("Comment")}
        onChange={(e) => {
          activity.text = e;
        }}
      />
    );
  };

  const renderVisibilityRadio = () => {
    const options: { title: string; value: VisibilityEnum }[] = [
      { title: _("Only me"), value: "ONLY_ME" },
      { title: _("Team"), value: "TEAM" },
      { title: _("Followers"), value: "FOLLOWERS" },
    ];
    return (
      <RadioPills<VisibilityEnum>
        items={options}
        selected={activity.visibility ?? "FOLLOWERS"}
        onSelect={(e) => {
          const newAct = { ...activity };
          newAct.visibility = e;
          setActivity(newAct);
        }}
      />
    );
  };

  const renderHorseDropdown = () => {
    let state = store.getState();
    const horses =
      props.simpleHorses?.map((e) => ({
        value: e.faedingarnumer!,
        title: e.name!,
      })) ?? [];
    return (
      <div className="flex flex-row justify-between">
        {!activity.horse ? (
          <div></div>
        ) : (
          <SimpleHorseCard simpleHorse={activity.horse!} />
        )}
        <DropdownButton<string>
          color="white"
          selected={activity.horse?.faedingarnumer}
          title="Horse"
          items={horses}
          onChange={(value) => {
            const newAct = { ...activity };
            newAct.horse = props.simpleHorses?.filter(
              (v) => v.faedingarnumer === value
            )[0]!;
            setActivity(newAct);
          }}
        />
      </div>
    );
  };

  const renderActivityInput = () => {
    if (activity.type === "MEDICAL") {
      return (
        <AddMedicalComponent
          activity={activity}
          onChange={(m) => {
            const act = { ...activity };
            act.medical = m;
            setActivity(act);
          }}
        />
      );
    }
    return <div></div>;
  };

  return (
    <div
      className="p-4 shadow-sm rounded-md bg-white "
      style={{
        minWidth: 500,
      }}
    >
      {/* <div className="text-lg font-medium">
        {isFuture(activity.dtPerformed!)
          ? "Plan an activity"
          : "Add an activity"}
      </div> */}
      {props.onClose != undefined && (
        <div className="flex flex-row justify-end">
          <CloseButton
            onClick={() => {
              props.onClose!(false);
            }}
          />
        </div>
      )}
      <div className="flex flex-row justify-between">
        <DropdownButton<ActivityTypeEnum>
          selected={activity.type}
          color={activityTypeColor(activity.type)}
          title={_("Select activity")}
          onChange={(e) => {
            const act = { ...activity };
            act.type = e;
            setActivity(act);
          }}
          items={savableActivityTypes.map((e) => ({
            value: e,
            title: activityTypeToString(e),
          }))}
        />
        <div className="pt-4 flex flex-col">
          <DatePicker
            selectedDate={activity.dtPerformed}
            onDateChange={(e) => {
              const act = { ...activity };
              act.dtPerformed = e;
              setActivity(act);
            }}
          />
          {/* <TimePicker /> */}
        </div>
      </div>
      <hr className="mt-4 mb-4" />
      <div className="pt-4">{renderHorseDropdown()}</div>
      <div className="pt-4">{renderActivityInput()}</div>
      <div className="pt-4">{renderNotesInput()}</div>
      <div className="pt-4">{renderVisibilityRadio()}</div>

      <div className="text-center pt-4">
        <Button
          loading={loadingSave}
          className="w-40"
          onClick={() => {
            handleSaveActivity();
          }}
        >
          {_("Save")}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStore, ownProps: any) => {
  const simpleHorses = state.horsesState.simpleHorses;
  return { simpleHorses };
};

export default connect(mapStateToProps)(AddActivityComponent);
