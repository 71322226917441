import {
  Horse,
  PublicUser,
  UserWeeklyTrainingSummary,
  WeeklyTrainingSummary,
} from "@/openapi";
import { _ } from "@/pages/_app";
import { captureException, createApiList } from "@/utils/devUtils";
import { accountsApi, activitiesApi, horsesApi } from "@/utils/NetworkUtils";
import {
  distanceToReadable,
  speedToReadable,
  timeToReadable,
} from "@/utils/stringUtils";
import { getWeek } from "date-fns";
import { useEffect, useState } from "react";
import GaitSummaryWidget from "./gaitSummaryWidget";
import { clsx } from "clsx";

interface ITrainingSummaryChart {
  publicUser?: PublicUser;
  horse?: Horse;
}
// distance, numRides, time
export class DataDNT {
  constructor(
    distance?: number,
    time?: number,
    numRides?: number,
    customDistance?: number,
    customTime?: number,
    customNumRides?: number,
    week?: number,
    year?: number,
    standingDistance?: number,
    walkDistance?: number,
    toltDistance?: number,
    trotDistance?: number,
    gallopDistance?: number,
    paceDistance?: number
  ) {
    this.distance = distance ?? 0;
    this.time = time ?? 0;
    this.numRides = numRides ?? 0;

    this.customDistance = customDistance ?? 0;
    this.customTime = customTime ?? 0;
    this.customNumRides = customNumRides ?? 0;

    this.week = week ?? 0;
    this.year = year ?? 2022;
    this.standingDistance = standingDistance ?? 0;
    this.walkDistance = walkDistance ?? 0;
    this.toltDistance = toltDistance ?? 0;
    this.trotDistance = trotDistance ?? 0;
    this.gallopDistance = gallopDistance ?? 0;
    this.paceDistance = paceDistance ?? 0;
  }
  distance?: number;
  time?: number;
  numRides?: number;

  customDistance?: number;
  customTime?: number;
  customNumRides?: number;

  week?: number;
  year?: number;

  standingDistance?: number;
  walkDistance?: number;
  toltDistance?: number;
  trotDistance?: number;
  gallopDistance?: number;
  paceDistance?: number;
}

const TrainingSummaryChart = (props: ITrainingSummaryChart) => {
  const [data, setData] = useState([] as DataDNT[]);
  const [chartIndex, setChartIndex] = useState(0);

  useEffect(() => {
    const getWeeks = (): [number[], number[]] => {
      const weeksThisYear = [] as number[];
      const weeksLastYear = [] as number[];
      const now = new Date();
      let week = getWeek(now);
      for (var i = 0; i < 10; i++) {
        weeksThisYear.push(week);
        week -= 1;
        if (week < 0) {
          // negative
          weeksLastYear.push(52 + week);
        }
      }
      return [weeksThisYear, weeksLastYear];
    };

    const fetchUserData = async () => {
      const [weeksThisYear, weeksLastYear] = getWeeks();
      const now = new Date();

      var res = await accountsApi.accountsUserWeeksTrainingSummaryList(
        props.publicUser?.uid,
        createApiList(weeksThisYear),
        // "[" + weeksThisYear.toString() + "]",
        now.getFullYear()
      );
      if (res) {
        processRes(res, weeksThisYear);
      }
    };

    const processRes = (
      res: UserWeeklyTrainingSummary[] | WeeklyTrainingSummary[],
      weeksThisYear: number[]
    ) => {
      const now = new Date();
      var newDatas = [] as DataDNT[];
      for (let item of res) {
        if (item) {
          newDatas.push(
            new DataDNT(
              item.distance,
              item.time,
              item.numRides,
              item.customDistance,
              item.customTime,
              item.customNumRides,

              item.week,
              item.year,

              item.standingDistance,
              item.walkDistance,
              item.toltDistance,
              item.trotDistance,
              item.gallopDistance,
              item.paceDistance
            )
          );
        }
      }
      for (var i of weeksThisYear) {
        let found = false;
        for (var d of newDatas) {
          if (d.week === i) {
            found = true;
          }
        }
        if (!found) {
          newDatas.push(new DataDNT(0, 0, 0, 0, 0, 0, i, now.getFullYear()));
        }
      }
      newDatas.sort((a, b) => (a.week ?? 0) - (b.week ?? 0));
      setChartIndex(newDatas.length - 1);

      setData(newDatas);
    };

    const fetchHorseData = async () => {
      const [weeksThisYear, weeksLastYear] = getWeeks();
      const now = new Date();
      try {
        var res =
          await activitiesApi.activitiesTrainingSummarySingleHorseWeeksList(
            props.horse?.faedingarnumer!
          );
        processRes(res, weeksThisYear);
      } catch (e) {
        captureException(e);
      }
    };

    if (props.publicUser) {
      fetchUserData();
    } else if (props.horse) {
      fetchHorseData();
    }
  }, [props.horse, props.publicUser]);

  if (data.length === 0) {
    return <></>;
  }

  const cData = data[chartIndex];
  const { distance, numRides, time, week } = cData;

  const renderArrows = () => {
    let text = _("Training in week") + " " + week;
    if (chartIndex === data.length - 1) {
      text = _("This weeks training");
    }
    return (
      <div className="flex flex-row justify-between text-sm font-bold">
        {text}
        <div className="flex flew-row">
          <button
            className={clsx(
              "cursor-pointer text-lg",
              chartIndex === 0 ? "text-lightest-gray" : "text-black"
            )}
            onClick={() => {
              if (chartIndex > 0) {
                setChartIndex(chartIndex - 1);
              }
            }}
          >
            {" "}
            {"<"}{" "}
          </button>
          <div className="pl-8"></div>
          <button
            className={clsx(
              "cursor-pointer text-lg",
              chartIndex === data.length - 1
                ? "text-lightest-gray"
                : "text-black"
            )}
            onClick={() => {
              if (chartIndex < data.length - 1) {
                setChartIndex(chartIndex + 1);
              }
            }}
          >
            {" "}
            {">"}{" "}
          </button>
        </div>
      </div>
    );
  };

  const renderCircle = (title: string, subtitle: string, color: string) => {
    return (
      <div
        className={
          color +
          " outline-1 h-20 w-20 rounded-full justify-center items-center m-2 p-1"
        }
      >
        <div className="bg-white outline-1 w-full h-full text-center rounded-full pt-4">
          <div className="text-xs" style={{ fontSize: 10 }}>
            {title}
          </div>
          <div className="text-xs font-medium">{subtitle}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderArrows()}
      <hr />
      <div className="flex flex-row justify-between">
        {renderCircle(
          distanceToReadable(distance),
          _("Distance"),
          "bg-grass-green"
        )}
        {renderCircle(timeToReadable(time), _("Time"), "bg-horse-day-orange")}
        {renderCircle(
          speedToReadable(distance, time),
          _("Speed"),
          "bg-burnt-orange"
        )}
      </div>
      <GaitSummaryWidget data={data[chartIndex]} />
    </div>
  );
};

export default TrainingSummaryChart;
