import { HorsedayIconProps } from "./IconProps";

const HorseIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  return (
    <svg
      className={props.className}
      width={props.width ?? "32"}
      height={props.height ?? "33"}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4109 9.61865C17.5151 9.61865 13.2533 9.61865 11.7431 9.61865C7.98044 9.61865 5.94553 12.4173 5.59998 14.7095C5.24163 17.0903 7.17416 19.8256 9.77218 20.0029V22.485L14.2387 25.5623"
        stroke="#1565C0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0287 7.42841C23.568 9.55592 26.0252 9.98649 26.0252 9.98649C26.4092 11.6201 28.2265 11.3035 28.2265 11.3035C28.7257 9.85985 28.6361 6.16204 28.6361 6.16204L29.6471 5.0223C29.6471 5.0223 25.5241 2.31581 21.4799 4.64594C19.4322 5.82367 18.7815 8.54282 18.4487 9.6319"
        stroke="#1565C0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.09326 29.0338L6.71973 24.0871L7.01996 18.6875"
        stroke="#1565C0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.312 16.7744H20.9176L26.6512 16.7871L27.7902 20.143"
        stroke="#1565C0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1763 16.7755C20.1763 16.7755 24.758 15.5218 24.3229 9.18994"
        stroke="#1565C0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2873 15.5063C15.4153 16.4055 15.2873 18.6845 12.8398 20.1245"
        stroke="#1565C0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3081 16.9526L24.1825 20.2832L21.1365 23.2592"
        stroke="#1565C0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.3999 16.1653C2.55348 13.8858 4.25557 9.61865 10.2067 9.61865H12.5488"
        stroke="#1565C0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default HorseIcon;
