import clsx from "clsx";

interface IRadioPills<T> {
  selected: T;
  items: { value: T; title: string }[];
  onSelect: (value: T) => void;
}

const RadioPills = <T,>(props: IRadioPills<T>) => {
  return (
    <div className="flex flex-row justify-center">
      {props.items.map((e) => {
        return (
          <div
            key={"pill" + e.title}
            onClick={() => {
              props.onSelect(e.value);
            }}
            style={{ minWidth: 80 }}
            className={clsx(
              "cursor-pointer rounded-full p-4 m-2 text-center",
              e.value === props.selected
                ? "bg-neutral-gray"
                : "bg-lightest-gray"
            )}
          >
            {e.title}
          </div>
        );
      })}
    </div>
  );
};

export default RadioPills;
